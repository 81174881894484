import React from 'react';

import styled from '@emotion/styled';

import * as Fuel from '@convoy/fuel';

import NoteHolder from '~/components/core/TrailerNotes/NoteHolder';
import { TrailerActivityFeedItem } from '~/services/apollo';

interface Props {
  threadContents: Array<TrailerActivityFeedItem>;
}

const strings = {
  noNotesAvailable: 'No notes available',
};

const NoNotesStyle = styled.div({
  textAlign: 'center',
  color: Fuel.Color.Dark.N600,
});

const NoteThreadHolder = (props: Props) => {
  const { threadContents } = props;
  threadContents.filter(content => content?.note);
  if (threadContents.length == 0) {
    return <NoNotesStyle>{strings.noNotesAvailable}</NoNotesStyle>;
  }
  return (
    <>
      {threadContents.map(content => (
        <NoteHolder
          note={content!.note!}
          createdAt={content!.createdAt}
          authorName={content!.author?.name}
        />
      ))}
    </>
  );
};

export default NoteThreadHolder;
