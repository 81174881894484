import React, { useState } from 'react';

import styled from '@emotion/styled';
import NotesSharp from '@material-ui/icons/NotesSharp';

import * as Fuel from '@convoy/fuel';

import TrailerNotes from '~/components/core/TrailerNotes/TrailerNotes';

export type TrailerNotesKeys = {
  trailerKey?: string | null;
  trailerServiceRequestId?: string | null;
  trailerNumber?: string;
  externalId?: string;
};

const StyledButton = styled(Fuel.Button)({
  padding: 0,
  height: 'auto',
  ...Fuel.Text.Size.SM,
});

const ViewNotesButton = (props: TrailerNotesKeys) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <StyledButton
        onClick={() => {
          setOpen(true);
        }}
        type={'link'}
      >
        <Fuel.Icon icon={NotesSharp} size='LG' />
      </StyledButton>
      {open && props.trailerServiceRequestId && props.trailerKey && (
        <TrailerNotes
          trailerKey={props.trailerKey}
          trailerNumber={props.trailerNumber}
          externalId={props.externalId}
          trailerServiceRequestId={props.trailerServiceRequestId}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default ViewNotesButton;
