import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';

import * as Fuel from '@convoy/fuel';

import { TableFilters } from '.';
import HasNotesFilter from './HasNotesFilter';
import LastUpdateFilter from './LastUpdateFilter';
import LocationCodeFilter from './LocationCodeFilter';
import OnlyOverdueFilter from './OnlyOverdueFilter';
import StatusFilter from './StatusFilter';

const strings = {
  moreFilters: 'More Filters',
  filters: {
    status: {
      label: 'Status',
      placeholder: 'Select a status',
    },
    locationCode: {
      label: 'Location Code',
      placeholder: 'Select a location code',
    },
    lastUpdate: {
      label: 'Last Update',
      placeholder: 'Select an last update range',
    },
    onlyOverdue: 'Overdue FHWA Only',
    hasNotes: 'Has Notes',
  },
  actions: {
    clearAll: 'Clear All',
    cancel: 'Cancel',
    apply: 'Apply',
  },
};

const filterStyles = {
  marginBottom: Fuel.Unit.MD,
};

const checkboxStyles = {
  marginBottom: Fuel.Unit.MD,
  marginLeft: Fuel.Unit.XXS,
};

const MoreFiltersButton = styled(Fuel.Button)({
  alignSelf: 'center',
});

const Drawer = styled.div({
  width: 350,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: Fuel.Unit.LG,
  backgroundColor: Fuel.Color.Light.N100,
});

const FilterLabel = styled(Fuel.Label)({
  '>div': {
    fontWeight: 'normal',
  },
});

const ActionButtons = styled.div({
  display: 'flex',
  marginTop: 'auto',
  gap: Fuel.Unit.MD,
  alignSelf: 'flex-end',
});

type FormValues = Pick<
  TableFilters,
  'statuses' | 'parcelIds' | 'lastUpdated' | 'hasNotes' | 'dueDate'
>;

interface Props {
  filters: TableFilters;
  onFilter: (filters: TableFilters) => void;
  onClearFilters: () => void;
}

const MoreFilters = ({ filters, onFilter, onClearFilters }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({});

  useEffect(() => setFormValues(filters), [filters]);

  const openDrawer = () => setIsOpen(true);
  const closeDrawer = () => setIsOpen(false);

  const clearForm = () => {
    onClearFilters();
    closeDrawer();
  };

  const updateForm = (filters: TableFilters) =>
    setFormValues(prevValues => ({ ...prevValues, ...filters }));

  const saveFilters = () => {
    onFilter(formValues);
    closeDrawer();
  };

  return (
    <>
      <MoreFiltersButton outlined size='SM' type='primary' onClick={openDrawer}>
        {strings.moreFilters}
      </MoreFiltersButton>

      <Fuel.Drawer isOpen={isOpen} onClose={closeDrawer} openFrom='right'>
        <Drawer>
          <FilterLabel label={strings.filters.status.label}>
            <StatusFilter
              css={filterStyles}
              placeholder={strings.filters.status.placeholder}
              filterValue={formValues.statuses?.[0]}
              onFilter={updateForm}
            />
          </FilterLabel>
          <FilterLabel label={strings.filters.locationCode.label}>
            <LocationCodeFilter
              css={filterStyles}
              placeholder={strings.filters.locationCode.placeholder}
              filterValue={formValues.parcelIds?.[0]}
              onFilter={updateForm}
            />
          </FilterLabel>
          <FilterLabel label={strings.filters.lastUpdate.label}>
            <LastUpdateFilter
              css={{ ...filterStyles, marginBottom: Fuel.Unit.LG }}
              placeholder={strings.filters.lastUpdate.placeholder}
              filterValue={formValues.lastUpdated?.filterValue}
              onFilter={updateForm}
            />
          </FilterLabel>

          <OnlyOverdueFilter
            css={checkboxStyles}
            label={strings.filters.onlyOverdue}
            filterValue={formValues.dueDate?.filterValue}
            onFilter={updateForm}
          />
          <HasNotesFilter
            css={checkboxStyles}
            label={strings.filters.hasNotes}
            filterValue={formValues.hasNotes}
            onFilter={updateForm}
          />

          <ActionButtons>
            <Fuel.Button size='SM' onClick={clearForm}>
              {strings.actions.clearAll}
            </Fuel.Button>
            <Fuel.Button
              size='SM'
              type='primary'
              outlined
              onClick={closeDrawer}
            >
              {strings.actions.cancel}
            </Fuel.Button>
            <Fuel.Button size='SM' type='primary' onClick={saveFilters}>
              {strings.actions.apply}
            </Fuel.Button>
          </ActionButtons>
        </Drawer>
      </Fuel.Drawer>
    </>
  );
};

export default MoreFilters;
