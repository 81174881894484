import React from 'react';

import { DateTime } from 'luxon';

import * as Fuel from '@convoy/fuel';
import { SelectProps } from '@convoy/fuel.form/Select/Select';

import { DateRangeFilter } from '~/services/apollo';

const options = [
  '< 3 days ago',
  '3 - 7 days ago',
  '7 - 14 days ago',
  '14 - 30 days ago',
  '> 30 days ago',
] as const;

type DayRangeFilterOption = typeof options[number];

export type LastUpdateFilterType = DateRangeFilter & {
  filterValue?: DayRangeFilterOption;
};

interface Props extends SelectProps {
  filterValue?: DayRangeFilterOption;
  onFilter: (filters: { lastUpdated: LastUpdateFilterType | null }) => void;
}

const LastUpdateFilter = (props: Props) => {
  const { filterValue, onFilter, ...selectProps } = props;

  const onChange = (event?: { value: any }) => {
    const { value } = event || {};
    if (!value) {
      onFilter({ lastUpdated: null });
    }
    if (options.includes(value)) {
      const lastUpdateFilter = convertDayRangeToFilter(
        value as DayRangeFilterOption,
      );
      onFilter({ lastUpdated: lastUpdateFilter });
    }
  };

  return (
    <Fuel.Select
      {...selectProps}
      isClearable
      value={filterValue ? { value: filterValue, label: filterValue } : null}
      options={options.map(option => ({ value: option, label: option }))}
      onChange={onChange}
    />
  );
};

const convertDayRangeToFilter = (
  dayRange: DayRangeFilterOption,
): LastUpdateFilterType => {
  switch (dayRange) {
    case '< 3 days ago':
      return {
        filterValue: dayRange,
        after: DateTime.fromJSDate(new Date())
          .minus({ days: 3 })
          .startOf('day'),
      };
    case '3 - 7 days ago':
      return {
        filterValue: dayRange,
        after: DateTime.fromJSDate(new Date())
          .minus({ days: 7 })
          .startOf('day'),
        before: DateTime.fromJSDate(new Date()).minus({ days: 3 }).endOf('day'),
      };
    case '7 - 14 days ago':
      return {
        filterValue: dayRange,
        after: DateTime.fromJSDate(new Date())
          .minus({ days: 14 })
          .startOf('day'),
        before: DateTime.fromJSDate(new Date()).minus({ days: 7 }).endOf('day'),
      };
    case '14 - 30 days ago':
      return {
        filterValue: dayRange,
        after: DateTime.fromJSDate(new Date())
          .minus({ days: 30 })
          .startOf('day'),
        before: DateTime.fromJSDate(new Date())
          .minus({ days: 14 })
          .endOf('day'),
      };
    case '> 30 days ago':
      return {
        filterValue: dayRange,
        before: DateTime.fromJSDate(new Date())
          .minus({ days: 30 })
          .endOf('day'),
      };
  }
};

export default LastUpdateFilter;
