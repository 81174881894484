import React, { FormEvent, useState } from 'react';

import styled from '@emotion/styled';
import ErrorOutlineSharp from '@material-ui/icons/ErrorOutlineSharp';
import KeyboardArrowDownSharp from '@material-ui/icons/KeyboardArrowDownSharp';
import KeyboardArrowRightSharp from '@material-ui/icons/KeyboardArrowRightSharp';
import WarningSharp from '@material-ui/icons/WarningSharp';
import { filter } from 'graphql-anywhere';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';

import * as Fuel from '@convoy/fuel';

import CopyToClipboard from '~/components/core/CopyToClipboard';
import { Checkbox } from '~/components/core/Form';
import ServiceRequestStatusSelect from '~/components/core/ServiceRequestStatusSelect';
import { BodyCell, TableRow } from '~/components/core/Table';
import TrailerDetailsCard from '~/components/core/TrailerDetailsCard';
import ViewNotesButton from '~/components/core/TrailerNotes/ViewNotesButton';
import ViewOnMapLink from '~/components/core/ViewOnMapLink';
import {
  LocationEventType,
  ServiceableTrailerInfoFragment,
  TrailerAndFacilityInfoFragmentDoc,
} from '~/services/apollo';
import { DEFAULT_EMPTY_VALUE } from '~/utils/strings';

const strings = {
  inTransit: 'In Transit',
  day: 'day',
  days: 'days',
};

const ToggleIcon = styled(Fuel.Icon)({
  ':hover': {
    cursor: 'pointer',
  },
});

const CopyToClipboardButton = styled(CopyToClipboard)({
  marginLeft: Fuel.Unit.XXS,
});

const YardRestrictionsIcon = styled(Fuel.Icon)({
  marginLeft: Fuel.Unit.XXS,
  color: Fuel.Color.Yellow.N500,
});

const StatusSelect = styled(ServiceRequestStatusSelect)({
  width: '95%',
});

const TrailerNumberCell = styled(BodyCell)({
  overflowWrap: 'break-word',
  minWidth: '100px',
});

const OverdueCell = styled(BodyCell)(
  ({ isOverdue }: { isOverdue?: boolean }) => ({
    color: isOverdue ? Fuel.Color.Red.N500 : Fuel.Color.Midnight,
  }),
);

const OverdueIcon = styled(Fuel.Icon)({
  marginRight: Fuel.Unit.XXS,
});

const MapButton = styled.div({
  width: 26,
  marginRight: Fuel.Unit.XL,
  textAlign: 'center',
});

const DetailsCard = styled(TrailerDetailsCard)({
  flexBasis: '100%',
  marginLeft: Fuel.Unit.XXL,
  marginRight: Fuel.Unit.XS,
  marginBottom: Fuel.Unit.XS,
});

interface Props {
  columnFlexSizes: Record<string, number>;
  trailer: ServiceableTrailerInfoFragment;
  selectedServiceRequests: Set<string>;
  onSelectServiceRequest: (event: FormEvent<HTMLInputElement>) => void;
}

const TrailerRow = (props: Props) => {
  const {
    columnFlexSizes,
    trailer,
    selectedServiceRequests,
    onSelectServiceRequest,
  } = props;

  const {
    key,
    number,
    trailerExternalReferences,
    activeServiceRequests,
    latestLocation,
    lastLocationEvent,
  } = trailer;

  // Each service provider can only assign a single external reference to a trailer
  const [externalReference] = trailerExternalReferences;
  const { externalId } = externalReference || {};

  let locationCode = DEFAULT_EMPTY_VALUE;
  if (lastLocationEvent?.parcel?.trailerYard?.locationCode) {
    if (lastLocationEvent.type === LocationEventType.Enter) {
      locationCode = lastLocationEvent.parcel.trailerYard.locationCode;
    } else {
      locationCode = strings.inTransit;
    }
  }

  const inTransit = lastLocationEvent?.type === LocationEventType.Exit;
  const hasRestrictions =
    lastLocationEvent?.parcel.trailerYard?.yardRestrictions?.opsNote;

  // This page only shows active FHWAs and there should only ever be one
  const [serviceRequest] = activeServiceRequests;
  const { id: serviceRequestId, status, dueDate, updatedAt } = serviceRequest;

  const due = DateTime.fromISO(dueDate);
  const dueDateDisplay = due.toLocaleString(DateTime.DATE_SHORT);
  const daysOverdue = Math.trunc(
    DateTime.fromJSDate(new Date()).endOf('day').diff(due, ['days']).toObject()
      .days || 0,
  );
  const isOverdue = daysOverdue > 0;

  const lastUpdateDisplay = DateTime.fromISO(updatedAt).toLocaleString(
    DateTime.DATE_SHORT,
  );

  const [latitude, longitute] = latestLocation?.coordinates?.coordinates || [];

  const [isExpandedDetails, setIsExpandedDetails] = useState(false);

  const handleDetailsIconClick = () => setIsExpandedDetails(!isExpandedDetails);

  return (
    <TableRow key={key} data-el='trailer-table-row'>
      <BodyCell css={{ flex: columnFlexSizes.expando }}>
        <ToggleIcon
          icon={
            isExpandedDetails ? KeyboardArrowDownSharp : KeyboardArrowRightSharp
          }
          onClick={handleDetailsIconClick}
        />
      </BodyCell>
      <BodyCell css={{ flex: columnFlexSizes.checkbox }}>
        <Checkbox
          value={serviceRequestId || ''}
          checked={selectedServiceRequests.has(serviceRequestId)}
          onChange={onSelectServiceRequest}
        />
      </BodyCell>
      <TrailerNumberCell css={{ flex: `${columnFlexSizes.convoyNumber} 0` }}>
        {number}
      </TrailerNumberCell>
      <TrailerNumberCell css={{ flex: `${columnFlexSizes.ryderNumber} 0` }}>
        {externalId || DEFAULT_EMPTY_VALUE}
        {externalId && <CopyToClipboardButton copyValue={externalId} />}
      </TrailerNumberCell>
      <BodyCell css={{ flex: columnFlexSizes.location }}>
        {locationCode}
        {!inTransit && hasRestrictions && (
          <YardRestrictionsIcon icon={ErrorOutlineSharp} size='SM' />
        )}
      </BodyCell>
      <BodyCell css={{ flex: columnFlexSizes.status }}>
        <StatusSelect
          trailerNumber={number}
          showColor
          serviceRequestId={serviceRequestId}
          status={status}
        />
      </BodyCell>
      <OverdueCell
        css={{ flex: columnFlexSizes.dueDate }}
        isOverdue={isOverdue}
      >
        {isOverdue && <OverdueIcon icon={WarningSharp} size='SM' />}
        {dueDateDisplay}
      </OverdueCell>
      <BodyCell css={{ flex: columnFlexSizes.lastUpdate }}>
        {lastUpdateDisplay}
      </BodyCell>
      <BodyCell
        css={{ flex: `${columnFlexSizes.actions} ${columnFlexSizes.actions}` }}
      >
        <MapButton>
          {latitude && longitute ? (
            <ViewOnMapLink trailerKey={trailer.key!} />
          ) : (
            DEFAULT_EMPTY_VALUE
          )}
        </MapButton>
        <div>
          <ViewNotesButton
            trailerKey={key}
            trailerNumber={number || DEFAULT_EMPTY_VALUE}
            externalId={externalId || DEFAULT_EMPTY_VALUE}
            trailerServiceRequestId={serviceRequestId}
          />
        </div>
      </BodyCell>
      {isExpandedDetails && (
        <DetailsCard {...filter(TrailerAndFacilityInfoFragmentDoc, trailer)} />
      )}
    </TableRow>
  );
};

TrailerRow.ServiceableTrailerInfo = gql`
  fragment ServiceableTrailerInfo on Trailer {
    id
    key
    number
    trailerExternalReferences(
      filters: { externalEntities: [$serviceProvider] }
    ) {
      externalId
    }
    activeServiceRequests(input: $searchOptions) {
      id
      type
      status
      dueDate
      updatedAt
    }
    latestLocation {
      coordinates {
        coordinates
      }
    }
    lastLocationEvent {
      type
      parcel {
        trailerYard {
          key
          locationCode
          yardRestrictions {
            opsNote
          }
        }
      }
    }
    ...TrailerAndFacilityInfo
  }
  ${TrailerDetailsCard.TrailerAndFacilityInfo}
`;

export default TrailerRow;
