import React, { useState, FormEvent, useEffect, useCallback } from 'react';

import styled from '@emotion/styled';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';

import * as Fuel from '@convoy/fuel';
import { usePusherEvent } from '@convoy/lynx.pusher';
import { useTestDrive } from '@convoy/lynx.testdrive';
import {
  PusherNotificationChannel,
  PusherEvent,
  TrailerPoolKey,
} from '@convoy/trailer-service-types';

import Modal from '~/components/core/Modal';
import {
  BulkUpdateStatusButton,
  TrailersTable,
} from '~/components/pages/serviceableTrailers';
import FiltersPanel, {
  TableFilters,
} from '~/components/pages/serviceableTrailers/FiltersPanel';
import SearchPanel from '~/components/pages/serviceableTrailers/SearchPanel';
import {
  tableSortConfigs,
  ServiceableTrailersSort,
} from '~/components/pages/serviceableTrailers/TrailersTable';
import TrailerRow from '~/components/pages/serviceableTrailers/TrailersTable/TrailerRow';
import { useUpdateTrailerServiceRequests } from '~/hooks';
import {
  useGetServiceableTrailersQuery,
  ExternalEntity,
  ServiceRequestType,
  ServiceRequestStatus,
  ServiceableTrailerInfoFragment,
  SortDirection,
  ServiceRequestSortField,
  ServiceRequestSearchParameters,
} from '~/services/apollo';
import { flags } from '~/services/testdrive/featureFlags';
import { compact } from '~/utils/nullish';
import {
  allOptions,
  doesStatusNeedConfirmation,
  getOptionsForStatuses,
} from '~/utils/serviceRequestStatus/ServiceRequestStatusOptions';
import { DEFAULT_EMPTY_VALUE } from '~/utils/strings';

const Header = styled.div({
  display: 'flex',
  marginBottom: Fuel.Unit.LG,
  justifyContent: 'space-between',
});

const Title = styled.h1({
  margin: 0,
  ...Fuel.Text.Size.MD,
});

const TrailerCount = styled.span({
  marginLeft: Fuel.Unit.SM,
  fontWeight: 'normal',
});

const Subtitle = styled.p({
  margin: 0,
  ...Fuel.Text.Size.SM,
  color: Fuel.Color.Dark.N800,
});

const TableControls = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const ButtonBar = styled.div({
  display: 'flex',
  marginBottom: Fuel.Unit.MD,
  gap: Fuel.Unit.MD,
  alignItems: 'center',
  justifyContent: 'flex-end',
});

const SelectedCounter = styled.p({
  margin: 0,
  ...Fuel.Text.Size.SM,
  color: Fuel.Color.Dark.N800,
  fontWeight: 'lighter',
});

const strings = {
  title: 'Serviceable Trailers',
  subtitle: 'All serviceable trailers appear here.',
  buttonBar: {
    selected: 'Selected',
    markAsCompleted: 'Mark as Completed',
  },
  confirmationModal: {
    headerText: `Do you want to update FHWA Inspection of $trailerCount selected trailers to '$newStatus'?`,
    bodyText: `This will automatically reschedule a new FHWA inspection for
      these trailers to next year.\n
      If confirm, you cannot undo this.\n`,
    cancelButton: `Cancel`,
    confirmButton: `Confirm`,
  },
};

export const Testing = {
  strings,
};

type BulkOperation = 'updating' | 'completing';

const ServiceableTrailers = () => {
  const [tableFilters, setTableFilters] = useState<TableFilters>({});
  const [tableSort, setTableSort] = useState<ServiceableTrailersSort>({
    fieldPath: tableSortConfigs.dueDate.path,
    fieldAlias: tableSortConfigs.dueDate.alias,
    sortDirection: SortDirection.Asc,
  });
  const [tableSearch, setTableSearch] =
    useState<ServiceRequestSearchParameters | null>(null);

  // Matches page size to backend default if pagination feature flag is not enabled
  const [shouldUsePagination] = useTestDrive([flags.usePagination]);
  const requestedPageSize = shouldUsePagination ? 20 : 6000;

  const { data, loading, variables, refetch, fetchMore, networkStatus } =
    useGetServiceableTrailersQuery({
      variables: {
        searchOptions: {
          filters: {
            types: [ServiceRequestType.FhwaInspection],
            trailerPoolKeys: [TrailerPoolKey.CONVOY_UTP_POOL_KEY],
            statuses: tableFilters.statuses,
            dueDate: {
              after: tableFilters.dueDate?.after,
              before:
                tableFilters.dueDate?.before ||
                DateTime.fromJSDate(new Date())
                  .plus({ days: 90 })
                  .endOf('day')
                  .toISO(),
            },
            lastUpdated: {
              after: tableFilters.lastUpdated?.after,
              before: tableFilters.lastUpdated?.before,
            },
            parcelIds: tableFilters.parcelIds,
            hasNotes: tableFilters.hasNotes,
          },
          sorts: [
            {
              field: tableSort.fieldPath,
              sortDirection: tableSort.sortDirection,
            },
          ],
          search: tableSearch,
          first: requestedPageSize,
        },
        serviceProvider: ExternalEntity.Ryder,
      },
      fetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
    });

  const { lastCursor, totalCount: totalFilteredCount } =
    data?.trailersWithActiveServiceRequests?.pageInfo || {};

  const [trailers, setTrailers] = useState<ServiceableTrailerInfoFragment[]>(
    [],
  );
  const [totalTrailersCount, setTotalTrailersCount] = useState<number>();
  const [serviceRequestIdToTrailersMap, setServiceRequestIdToTrailersMap] =
    useState<Map<string, ServiceableTrailerInfoFragment>>(new Map());
  const [selectedServiceRequests, setSelectedServiceRequests] = useState(
    new Set<string>(),
  );
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isBulkUpdating, setIsBulkUpdating] = useState(false);
  const [isBulkCompleting, setIsBulkCompleting] = useState(false);
  const [bulkUpdateNewStatus, setBulkUpdateNewStatus] =
    useState<ServiceRequestStatus>();
  const [bulkUpdateOperation, setBulkUpdateOperation] =
    useState<BulkOperation>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [updateServiceRequests] = useUpdateTrailerServiceRequests();

  useEffect(() => {
    const trailerData = compact(data?.trailersWithActiveServiceRequests?.edges);
    setTrailers(trailerData);

    const map = trailerData.reduce((currentMap, trailer) => {
      if (trailer.activeServiceRequests.length > 0) {
        currentMap.set(trailer.activeServiceRequests[0].id, trailer);
      }
      return currentMap;
    }, new Map());
    setServiceRequestIdToTrailersMap(map);
  }, [data]);

  useEffect(() => {
    // We want to display a count of total serviceable trailers. Instead of
    // making a separate query, set the total count on the initial unfiltered
    // request and only update if we make another unfiltered request.
    if (Object.keys(tableFilters).length === 0 && !tableSearch) {
      setTotalTrailersCount(totalFilteredCount || 0);
    }
  }, [tableFilters, tableSearch, totalFilteredCount]);

  usePusherEvent({
    channelName: PusherNotificationChannel.FLEET_CARE_WEB,
    eventName: PusherEvent.ACTIVE_SERVICE_REQUESTS_UPDATED,
    callback() {
      refetch(variables);
    },
  });

  const onFilter = useCallback(
    (filters: TableFilters) =>
      setTableFilters(prevFilters => ({ ...prevFilters, ...filters })),
    [],
  );

  const onClearFilters = () => setTableFilters({});

  const onSort = (
    _: any[],
    sortedBy: string,
    sortDirection: SortDirection,
    fieldAlias?: string,
  ) => {
    const isValidSortField = Object.values(ServiceRequestSortField).includes(
      sortedBy as unknown as ServiceRequestSortField,
    );
    if (isValidSortField && fieldAlias) {
      setTableSort({
        fieldPath: sortedBy as ServiceRequestSortField,
        sortDirection,
        fieldAlias,
      });
    }
  };

  const onSearch = (search: ServiceRequestSearchParameters | null) =>
    setTableSearch(search);

  const onFetchMoreTrailers = () => {
    // values provided overwrite existing variables, omitted values remain unchanged
    const fetchMoreVariables = {
      variables: {
        searchOptions: {
          ...variables?.searchOptions,
          after: lastCursor,
        },
      },
    };
    if (!loading) fetchMore(fetchMoreVariables);
  };

  const onSelectServiceRequest = (event: FormEvent<HTMLInputElement>) => {
    const serviceRequestId = event.currentTarget.value;

    // If the target service request is selected and therefore being deselected,
    // ensure isAllSelected is set to false
    if (selectedServiceRequests.has(serviceRequestId) && isAllSelected) {
      setIsAllSelected(false);
    }

    setSelectedServiceRequests(selected => {
      const updatedSelected = new Set(selected);
      if (updatedSelected.has(serviceRequestId)) {
        updatedSelected.delete(serviceRequestId);
      } else {
        updatedSelected.add(serviceRequestId);
      }
      return updatedSelected;
    });
  };

  const onSelectAllServiceRequests = () => {
    const selected = new Set<string>();
    if (!isAllSelected) {
      trailers.forEach(trailer => {
        selected.add(trailer.activeServiceRequests[0].id);
      });
    }
    setSelectedServiceRequests(selected);
    setIsAllSelected(!isAllSelected);
  };

  const onBulkUpdate = async (status: ServiceRequestStatus) => {
    setBulkUpdateOperation('updating');
    handleBulkUpdate(status);
  };

  const onBulkComplete = async () => {
    setBulkUpdateOperation('completing');
    handleBulkUpdate(ServiceRequestStatus.Completed);
  };

  const handleBulkUpdate = async (newStatus: ServiceRequestStatus) => {
    setBulkUpdateNewStatus(newStatus);
    if (doesStatusNeedConfirmation(newStatus)) {
      setShowConfirmationModal(true);
    } else {
      doBulkUpdate(newStatus);
    }
  };

  const setInProgress = (isInprogress: boolean) => {
    if (bulkUpdateOperation === 'updating') {
      setIsBulkUpdating(isInprogress);
    } else if (bulkUpdateOperation === 'completing') {
      setIsBulkCompleting(isInprogress);
    }
  };
  const doBulkUpdate = async (newStatus: ServiceRequestStatus) => {
    setInProgress(true);
    await updateServiceRequests({
      ids: Array.from(selectedServiceRequests),
      replaceWith: {
        status: newStatus,
      },
    });
    setInProgress(false);
    setSelectedServiceRequests(new Set());
    setIsAllSelected(false);
    setBulkUpdateNewStatus(undefined);
    setBulkUpdateOperation(undefined);
  };

  const handleModalOnConfirm = async () => {
    doBulkUpdate(bulkUpdateNewStatus!);
    setShowConfirmationModal(false);
  };

  const handleModalOnClose = () => {
    setShowConfirmationModal(false);
    setBulkUpdateNewStatus(undefined);
    setBulkUpdateOperation(undefined);
  };

  const getOptionsForSelectedServiceRequests = () => {
    const statuses: Set<ServiceRequestStatus> = new Set();
    selectedServiceRequests.forEach(requestId => {
      if (serviceRequestIdToTrailersMap.has(requestId)) {
        const trailer = serviceRequestIdToTrailersMap.get(requestId);
        if (trailer && trailer!.activeServiceRequests?.length > 0) {
          statuses.add(trailer!.activeServiceRequests[0]!.status);
        }
      }
    });

    return getOptionsForStatuses(statuses);
  };

  return (
    <>
      <Header>
        <div>
          <Title>
            {strings.title}
            <TrailerCount data-el='trailer-counts'>
              {loading ? DEFAULT_EMPTY_VALUE : totalFilteredCount} /{' '}
              {totalTrailersCount || DEFAULT_EMPTY_VALUE}
            </TrailerCount>
          </Title>
          <Subtitle>{strings.subtitle}</Subtitle>
        </div>
        <FiltersPanel
          filters={tableFilters}
          onFilter={onFilter}
          onClearFilters={onClearFilters}
        />
      </Header>

      <TableControls>
        <SearchPanel onSearch={onSearch} />
        <ButtonBar>
          <SelectedCounter>
            {selectedServiceRequests.size} {strings.buttonBar.selected}
          </SelectedCounter>
          <BulkUpdateStatusButton
            onBulkUpdate={onBulkUpdate}
            options={getOptionsForSelectedServiceRequests()}
            spinner={isBulkUpdating}
            disabled={selectedServiceRequests.size <= 0}
          />
          <Fuel.Button
            outlined
            size='SM'
            onClick={onBulkComplete}
            spinner={isBulkCompleting}
            disableOnSpin
            disabled={selectedServiceRequests.size <= 0}
          >
            {strings.buttonBar.markAsCompleted}
          </Fuel.Button>
        </ButtonBar>
      </TableControls>

      <TrailersTable
        networkStatus={networkStatus}
        trailers={trailers}
        tableSort={tableSort}
        onSort={onSort}
        selectedServiceRequests={selectedServiceRequests}
        isAllSelected={isAllSelected}
        onSelectServiceRequest={onSelectServiceRequest}
        onSelectAllServiceRequests={onSelectAllServiceRequests}
        onFetchMoreTrailers={onFetchMoreTrailers}
        totalFilteredCount={totalFilteredCount}
      />
      <Modal
        isOpen={showConfirmationModal}
        onClose={handleModalOnClose}
        title={strings.confirmationModal.headerText
          .replace('$trailerCount', `${selectedServiceRequests.size}`)
          .replace(
            '$newStatus',
            bulkUpdateNewStatus ? allOptions[bulkUpdateNewStatus].label : '--',
          )}
        actionButtonFunction={handleModalOnConfirm}
        actionButtonText={strings.confirmationModal.confirmButton}
        size={'MD'}
      >
        {strings.confirmationModal.bodyText}
      </Modal>
    </>
  );
};

ServiceableTrailers.query = gql`
  query getServiceableTrailers(
    $searchOptions: ServiceRequestSearchOptions
    $serviceProvider: ExternalEntity!
  ) {
    trailersWithActiveServiceRequests(input: $searchOptions) {
      edges {
        ...ServiceableTrailerInfo
      }
      pageInfo {
        lastCursor
        totalCount
      }
    }
  }
  ${TrailerRow.ServiceableTrailerInfo}
`;

export default ServiceableTrailers;
