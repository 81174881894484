import React from 'react';

import styled from '@emotion/styled';

import * as Fuel from '@convoy/fuel';

import { ServiceRequestFilters } from '~/services/apollo';

import FilterTag from './FilterTag';
import { LastUpdateFilterType } from './LastUpdateFilter';
import LocationCodeFilter from './LocationCodeFilter';
import MoreFilters from './MoreFilters';
import { DueDateFilterType } from './OnlyOverdueFilter';
import StatusFilter from './StatusFilter';

const Container = styled.div({
  padding: Fuel.Unit.SM,
  backgroundColor: Fuel.Color.Light.N100,
});

const Controls = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const ClearFiltersButton = styled(Fuel.Button)({
  padding: 0,
});

const Filters = styled.div({
  display: 'flex',
  marginLeft: Fuel.Unit.SM,
  gap: Fuel.Unit.LG,
});

const Tags = styled.div({
  display: 'flex',
  flexDirection: 'row-reverse',
  gap: Fuel.Unit.XS,
});

const Tag = styled(FilterTag)({
  marginTop: Fuel.Unit.SM,
});

const filterStyles = {
  minWidth: 200,
};

const strings = {
  clearFilters: 'Clear All',
  status: 'Status',
  locationCode: 'Location Code',
  lastUpdated: 'Last updated',
  overdueOnly: 'Overdue only',
  hasNotes: 'Has notes',
};

export interface TableFilters
  extends Omit<ServiceRequestFilters, 'dueDate' | 'lastUpdated'> {
  dueDate?: DueDateFilterType | null;
  lastUpdated?: LastUpdateFilterType | null;
}

interface Props {
  filters: TableFilters;
  onFilter: (filters: TableFilters) => void;
  onClearFilters: () => void;
}

const FiltersPanel = ({ filters, onFilter, onClearFilters }: Props) => (
  <Container data-el='filters-panel'>
    <Controls>
      <ClearFiltersButton size='SM' type='link' onClick={onClearFilters}>
        {strings.clearFilters}
      </ClearFiltersButton>
      <Filters>
        <StatusFilter
          css={filterStyles}
          size='SM'
          placeholder={strings.status}
          filterValue={filters.statuses?.[0]}
          onFilter={onFilter}
        />
        <LocationCodeFilter
          css={filterStyles}
          size='SM'
          placeholder={strings.locationCode}
          filterValue={filters.parcelIds?.[0]}
          onFilter={onFilter}
        />
        <MoreFilters
          filters={filters}
          onFilter={onFilter}
          onClearFilters={onClearFilters}
        />
      </Filters>
    </Controls>
    <Tags>
      <Tag
        showTag={!!filters.lastUpdated?.filterValue}
        text={`${strings.lastUpdated} ${filters.lastUpdated?.filterValue}`}
        onClose={() => onFilter({ lastUpdated: null })}
      />
      <Tag
        showTag={!!filters.dueDate?.filterValue}
        text={strings.overdueOnly}
        onClose={() => onFilter({ dueDate: null })}
      />
      <Tag
        showTag={!!filters.hasNotes}
        text={strings.hasNotes}
        onClose={() => onFilter({ hasNotes: null })}
      />
    </Tags>
  </Container>
);

export default FiltersPanel;
