import React from 'react';

import * as Fuel from '@convoy/fuel';
import { SelectProps } from '@convoy/fuel.form/Select/Select';

import { ServiceRequestStatus } from '~/services/apollo';

const options = [
  {
    value: ServiceRequestStatus.NotRequested,
    label: 'Not Requested',
  },
  {
    value: ServiceRequestStatus.Requested,
    label: 'Requested',
  },
  {
    value: ServiceRequestStatus.Deferred,
    label: 'Deferred',
  },
  {
    value: ServiceRequestStatus.InProgress,
    label: 'In Progress',
  },
  {
    value: ServiceRequestStatus.NotAllowedOnSite,
    label: 'Not Allowed On-Site',
  },
  {
    value: ServiceRequestStatus.TrailerNotAccessible,
    label: 'Trailer Not Accessible',
  },
  {
    value: ServiceRequestStatus.TrailerNotFound,
    label: 'Trailer Not Found',
  },
  {
    value: ServiceRequestStatus.UnableToComplete,
    label: 'Unable to Complete',
  },
  {
    value: ServiceRequestStatus.CompletedWithFollowUps,
    label: 'Completed + Follow-ups',
  },
];

interface Props extends SelectProps {
  filterValue?: string;
  onFilter: (filters: { statuses: ServiceRequestStatus[] | null }) => void;
}

const StatusFilter = (props: Props) => {
  const { filterValue, onFilter, ...selectProps } = props;

  const selectedOption = options.find(option => option.value === filterValue);

  const onChange = (event?: { value: string }) => {
    const { value } = event || {};
    onFilter({ statuses: value ? [value as ServiceRequestStatus] : null });
  };

  return (
    <Fuel.Select
      {...selectProps}
      isClearable
      value={selectedOption ?? null}
      options={options}
      onChange={onChange}
    />
  );
};

export default StatusFilter;
