import React, { FormEvent } from 'react';

import { Checkbox } from '~/components/core/Form';
import {
  Table,
  TableHeader,
  HeaderCell,
  SortIcon,
} from '~/components/core/Table';
import {
  ServiceableTrailerInfoFragment,
  ServiceRequestSortField,
  SortDirection,
} from '~/services/apollo';

import TrailersTableBody from './TrailersTableBody';

const provider = 'Ryder';

const strings = {
  tableHeaders: {
    convoyNumber: 'Convoy #',
    externalId: `${provider} #`,
    location: 'Location',
    status: 'Status',
    dueDate: 'Due Date',
    lastUpdated: 'Last Updated',
    actions: 'Actions',
  },
};

const columnFlexSizes = {
  expando: 0.5,
  checkbox: 0.5,
  convoyNumber: 3,
  ryderNumber: 3,
  location: 3,
  status: 6,
  dueDate: 3,
  lastUpdate: 3,
  actions: 9,
};

export const tableSortConfigs = {
  dueDate: {
    path: ServiceRequestSortField.DueDate,
    alias: 'dueDate',
  },
  lastUpdate: {
    path: ServiceRequestSortField.UpdatedAt,
    alias: 'lastUpdate',
  },
};

export type ServiceableTrailersSort = {
  fieldPath: ServiceRequestSortField;
  fieldAlias: string;
  sortDirection: SortDirection;
};

interface Props {
  networkStatus: number;
  trailers: ServiceableTrailerInfoFragment[];
  tableSort: ServiceableTrailersSort;
  onSort: (
    sortedRows: ServiceableTrailerInfoFragment[],
    sortedBy: string,
    sortDirection: SortDirection,
  ) => void;
  selectedServiceRequests: Set<string>;
  isAllSelected: boolean;
  onSelectServiceRequest: (event: FormEvent<HTMLInputElement>) => void;
  onSelectAllServiceRequests: () => void;
  onFetchMoreTrailers: () => void;
  totalFilteredCount?: number | null;
}

const TrailersTable = (props: Props) => {
  const {
    networkStatus,
    trailers,
    tableSort,
    onSort,
    selectedServiceRequests,
    isAllSelected,
    onSelectServiceRequest,
    onSelectAllServiceRequests,
    onFetchMoreTrailers,
    totalFilteredCount,
  } = props;

  return (
    <Table>
      <TableHeader>
        <HeaderCell css={{ flex: columnFlexSizes.expando }}></HeaderCell>
        <HeaderCell css={{ flex: columnFlexSizes.checkbox }}>
          <Checkbox
            checked={isAllSelected}
            onChange={onSelectAllServiceRequests}
          />
        </HeaderCell>
        <HeaderCell
          css={{
            flex: columnFlexSizes.convoyNumber,
            overflowWrap: 'break-word',
            minWidth: '100px',
          }}
        >
          {strings.tableHeaders.convoyNumber}
        </HeaderCell>
        <HeaderCell
          css={{
            flex: columnFlexSizes.ryderNumber,
            overflowWrap: 'break-word',
            minWidth: '100px',
          }}
        >
          {strings.tableHeaders.externalId}
        </HeaderCell>
        <HeaderCell css={{ flex: columnFlexSizes.location }}>
          {strings.tableHeaders.location}
        </HeaderCell>
        <HeaderCell css={{ flex: columnFlexSizes.status }}>
          {strings.tableHeaders.status}
        </HeaderCell>
        <HeaderCell css={{ flex: columnFlexSizes.dueDate }}>
          {strings.tableHeaders.dueDate}
          <SortIcon
            isServerSort
            data={trailers}
            onSort={onSort}
            sortFilter={{
              fieldPath: tableSortConfigs.dueDate.path,
              fieldAlias: tableSortConfigs.dueDate.alias,
              sortDirection:
                tableSortConfigs.dueDate.alias === tableSort.fieldAlias
                  ? tableSort.sortDirection
                  : null,
            }}
          />
        </HeaderCell>
        <HeaderCell css={{ flex: columnFlexSizes.lastUpdate }}>
          {strings.tableHeaders.lastUpdated}
          <SortIcon
            isServerSort
            data={trailers}
            onSort={onSort}
            sortFilter={{
              fieldPath: tableSortConfigs.lastUpdate.path,
              fieldAlias: tableSortConfigs.lastUpdate.alias,
              sortDirection:
                tableSortConfigs.lastUpdate.alias === tableSort.fieldAlias
                  ? tableSort.sortDirection
                  : null,
            }}
          />
        </HeaderCell>
        <HeaderCell
          css={{
            flex: `${columnFlexSizes.actions} ${columnFlexSizes.actions}`,
          }}
        >
          {strings.tableHeaders.actions}
        </HeaderCell>
      </TableHeader>
      <TrailersTableBody
        networkStatus={networkStatus}
        columnFlexSizes={columnFlexSizes}
        trailers={trailers}
        selectedServiceRequests={selectedServiceRequests}
        onSelectServiceRequest={onSelectServiceRequest}
        onFetchMoreTrailers={onFetchMoreTrailers}
        totalFilteredCount={totalFilteredCount}
      />
    </Table>
  );
};

export default TrailersTable;
