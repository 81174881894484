import React from 'react';

import styled from '@emotion/styled';
import CloseSharp from '@material-ui/icons/CloseSharp';

import * as Fuel from '@convoy/fuel';

const Content = styled.div({
  display: 'flex',
  gap: Fuel.Unit.XXS,
});

const CloseButton = styled(Fuel.Icon)({
  cursor: 'pointer',
});

interface Props {
  className?: string;
  showTag: boolean;
  text: string;
  onClose: () => void;
}

const FilterTag = ({ className, showTag, text, onClose }: Props) => {
  if (!showTag) {
    return null;
  }

  return (
    <Fuel.Tag className={className} size='SM' color='Dark' subtle>
      <Content>
        {text}
        <CloseButton icon={CloseSharp} onClick={onClose} />
      </Content>
    </Fuel.Tag>
  );
};

export default FilterTag;
