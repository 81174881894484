import React, { useEffect, useState } from 'react';

import { gql } from '@apollo/client';
import styled from '@emotion/styled';
import { ClearSharp } from '@material-ui/icons/';

import * as Fuel from '@convoy/fuel';

import NoteThreadHolder from '~/components/core/TrailerNotes/NoteThreadHolder';
import useCreateTrailerServiceRequestReply from '~/hooks/trailerNotes/useCreateTrailerServiceRequestReply';
import {
  TrailerActivityFeedItem,
  useTrailerNotesThreadQuery,
} from '~/services/apollo';
import { useViewer } from '~/services/viewer';
import { compact } from '~/utils/nullish';

interface Props {
  onClose: () => void;
  trailerKey: string;
  trailerNumber?: string;
  externalId?: string;
  trailerServiceRequestId: string;
}

const strings = {
  notesHeader: 'Trailer Notes',
  addANoteNotice: 'Add a note...',
  addNoteButton: 'Add note',
  trailerNumberDivider: ' | ',
};

const Layout = {
  notesWidth: '23vw',
  scrollableHolderHeight: '70vh',
};

const TrailerNotesFrame = styled(Fuel.Drawer)({
  zIndex: Fuel.Elevation.Z200.zIndex,
});

const AddNotesFrame = styled.div({
  backgroundColor: Fuel.Color.Light.N200,
  padding: Fuel.Unit.MD,
  ...Fuel.Text.Size.XS,
});

const TrailerNotesHeader = styled(Fuel.Card.Header)({
  justifyContent: 'space-between',
  flexDirection: 'row',
  display: 'flex',
  paddingBottom: Fuel.Unit.XS,
});

const TrailerNumberText = styled.div({
  ...Fuel.Text.Size.SM,
  paddingBottom: Fuel.Unit.SM,
});

const ScrollableHolder = styled.div({
  padding: Fuel.Unit.MD,
  overflowY: 'scroll',
  width: Layout.notesWidth,
  maxHeight: Layout.scrollableHolderHeight,
});

const POLLING_THREAD_INTERVAL = 2000;

const TrailerNotes = (props: Props) => {
  const {
    onClose,
    trailerKey,
    trailerNumber,
    externalId,
    trailerServiceRequestId,
  } = props;
  const [noteThreadContents, setNoteThreadContents] = useState<
    Array<TrailerActivityFeedItem>
  >([]);
  const [isAddNoteInProgress, setAddNoteInProgress] = useState<boolean>(false);
  const [parentId, setParentId] = useState<string | undefined>(undefined);
  const { data, loading, startPolling, stopPolling } =
    useTrailerNotesThreadQuery({
      variables: {
        trailerKey,
        trailerServiceRequestId,
      },
    });
  startPolling(POLLING_THREAD_INTERVAL);
  useEffect(() => {
    setNoteThreadContents(
      compact(data?.trailer?.activityFeedItem?.replies?.edges),
    );
    setParentId(data?.trailer?.activityFeedItem?.id ?? undefined);
  }, [data]);
  const [currentNote, setCurrentNote] = useState<string>('');
  const viewerId = useViewer()?.id;
  const [addNoteFn] = useCreateTrailerServiceRequestReply();
  const handleAddNote = async () => {
    if (!parentId || !viewerId || currentNote.length < 1) {
      return;
    }
    setAddNoteInProgress(true);
    await addNoteFn({
      note: currentNote,
      trailerKey,
      trailerServiceRequestId,
      parentId,
      actorId: viewerId,
    });
    setCurrentNote('');
    setAddNoteInProgress(false);
  };
  const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentNote(event.target.value);
  };

  const onCloseTrailerNote = () => {
    stopPolling();
    onClose();
  };

  return (
    <TrailerNotesFrame
      isOpen={true}
      onClose={onCloseTrailerNote}
      openFrom={'right'}
    >
      <AddNotesFrame>
        <TrailerNotesHeader>
          {strings.notesHeader}
          <Fuel.Icon icon={ClearSharp} onClick={onCloseTrailerNote} />
        </TrailerNotesHeader>
        <TrailerNumberText>
          {trailerNumber}
          {strings.trailerNumberDivider}
          {externalId}
        </TrailerNumberText>
        <Fuel.Card.Body>
          <Fuel.Textarea
            placeholder={strings.addANoteNotice}
            value={currentNote}
            onChange={handleNoteChange}
          />
        </Fuel.Card.Body>
        <Fuel.Card.Footer>
          <Fuel.Button
            type='primary'
            onClick={handleAddNote}
            disabled={!parentId || !(currentNote?.length > 0)}
            disableOnSpin
            spinner={isAddNoteInProgress}
          >
            {strings.addNoteButton}
          </Fuel.Button>
        </Fuel.Card.Footer>
      </AddNotesFrame>
      <ScrollableHolder>
        {loading ? (
          <Fuel.Spinner size='LG' />
        ) : (
          <NoteThreadHolder threadContents={noteThreadContents} />
        )}
      </ScrollableHolder>
    </TrailerNotesFrame>
  );
};

export default TrailerNotes;

TrailerNotes.query = gql`
  query TrailerNotesThread($trailerKey: ID!, $trailerServiceRequestId: ID!) {
    trailer(key: $trailerKey) {
      id
      key
      activityFeedItem(trailerServiceRequestId: $trailerServiceRequestId) {
        id
        replies {
          edges {
            note
            author {
              driverModeEnabled
              id
              name: realName
            }
            id
            createdAt
          }
        }
      }
    }
  }
`;

TrailerNotes.addNote = gql`
  mutation createNewActivityFeedItem(
    $eventType: ExternallyPublishableEventTopics
    $actorId: ID
    $data: ActivityFeedItemDataInput
    $entityKeys: ActivityFeedItemKeysInput
  ) {
    createNewActivityFeedItem(
      eventType: $eventType
      actorId: $actorId
      data: $data
      entityKeys: $entityKeys
    )
  }
`;
