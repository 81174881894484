import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import ClearSharp from '@material-ui/icons/ClearSharp';
import SearchSharp from '@material-ui/icons/SearchSharp';

import * as Fuel from '@convoy/fuel';

import {
  ServiceRequestSearchParameters,
  SupportedSearchConditions,
  SupportedSearchFields,
} from '~/services/apollo';

const strings = {
  searchInput: {
    placeholder: 'Trailer Number or VIN',
  },
  errorText: 'Search must be at least 3 characters',
};

export const Testing = {
  strings,
};

const SearchContainer = styled.div({
  display: 'flex',
  flex: '.35',
  alignItems: 'center',
  justifyContent: 'flex-start',
  minWidth: '200px',
  marginBottom: Fuel.Unit.MD,
});

const SearchInput = styled(Fuel.Input.Text)({
  width: '100%',
});

const SearchResetButton = styled(Fuel.Button.Icon)({
  color: Fuel.Color.Dark.N900,
  background: Fuel.Color.Light.N200,
  height: Fuel.Unit.MD,
  width: Fuel.Unit.MD,
  padding: Fuel.Unit.XS,
  borderRadius: '100%',
});

interface Props {
  onSearch: (search: ServiceRequestSearchParameters | null) => void;
}

const SearchPanel = (props: Props) => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const [isErrored, setIsErrored] = useState(false);
  const { onSearch } = props;

  const handleReset = () => setSearchInputValue('');

  useEffect(() => {
    setIsErrored(false);
    const isValidSearch = searchInputValue.length >= 3;
    const searchParameters: ServiceRequestSearchParameters | null =
      isValidSearch
        ? {
            searchTerm: searchInputValue,
            fields: [
              SupportedSearchFields.PartialExternalId,
              SupportedSearchFields.PartialTrailerNumber,
              SupportedSearchFields.PartialVin,
            ],
            condition: SupportedSearchConditions.Or,
          }
        : null;
    const timer = setTimeout(() => {
      setIsErrored(!isValidSearch && searchInputValue.length > 0);
      onSearch(searchParameters);
    }, 800);
    return () => clearTimeout(timer);
  }, [searchInputValue, onSearch]);

  const handleInput: React.FormEventHandler<HTMLInputElement> = event => {
    const searchString = event.currentTarget.value
      .trim()
      .replace(/[^\w\d]/gi, '')
      .toUpperCase();
    setSearchInputValue(searchString);
  };

  return (
    <SearchContainer>
      <SearchInput
        size='SM'
        prefix={<Fuel.Icon icon={SearchSharp} size='MD' />}
        suffix={
          <SearchResetButton
            icon={ClearSharp}
            size='SM'
            hidden={!searchInputValue}
            onClick={handleReset}
            data-el='search-reset-button'
          />
        }
        placeholder={strings.searchInput.placeholder}
        value={searchInputValue || ''}
        status={isErrored ? 'error' : 'default'}
        statusHelper={strings.errorText}
        onChange={handleInput}
      />
    </SearchContainer>
  );
};

export default SearchPanel;
