import React from 'react';

import ArrowDropDownSharp from '@material-ui/icons/ArrowDropDownSharp';

import * as Fuel from '@convoy/fuel';

import { ServiceRequestStatus } from '~/services/apollo';
import { ServiceRequestStatusOption } from '~/utils/serviceRequestStatus/ServiceRequestStatusOptions';

const strings = {
  updateStatus: 'Update Status',
};
interface Props {
  onBulkUpdate: (status: ServiceRequestStatus) => void;
  options: ServiceRequestStatusOption[];
  spinner?: boolean;
  disabled?: boolean;
}

const BulkUpdateStatusButton = (props: Props) => {
  const { onBulkUpdate, options, spinner, disabled } = props;

  return (
    <Fuel.Dropdown
      popper={
        <Fuel.Button
          outlined
          size='SM'
          spinner={spinner}
          disableOnSpin
          disabled={disabled}
        >
          {strings.updateStatus}
          <Fuel.Icon icon={ArrowDropDownSharp} css={{ height: 17 }} />
        </Fuel.Button>
      }
    >
      {options.map(option => (
        <Fuel.Dropdown.Link
          key={option.value}
          onClick={() => onBulkUpdate(option.value)}
          disabled={disabled}
        >
          {option.label}
        </Fuel.Dropdown.Link>
      ))}
    </Fuel.Dropdown>
  );
};

export default BulkUpdateStatusButton;
