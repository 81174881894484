import React from 'react';

import styled from '@emotion/styled';
import { DateTime } from 'luxon';

import * as Fuel from '@convoy/fuel';

interface Props {
  authorName?: string | null;
  note?: string | null;
  createdAt?: Date;
}

const dateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
  hour12: true,
} as const;

const NoteContainer = styled(Fuel.Container)({
  padding: Fuel.Unit.XS,
});

const NoteBody = styled(Fuel.Card)({
  color: Fuel.Color.Dark.N900,
  backgroundColor: Fuel.Color.Light.N300,
  padding: Fuel.Unit.XS,
  ...Fuel.Text.Size.MD,
  // wrap word if too long
  overflowWrap: 'break-word',
  // allow '\n' line breaks
  whiteSpace: 'pre-line',
  // align width
  width: '100%',
  boxSizing: 'border-box',
});

const NoteFooter = styled.div({
  color: Fuel.Color.Dark.N800,
  padding: Fuel.Unit.XXS,
  ...Fuel.Text.Size.XS,
});

const NoteHolder = (props: Props) => {
  const { note, createdAt, authorName } = props;
  const time = createdAt ? DateTime.fromJSDate(new Date(createdAt)) : undefined;
  const noteFooterContent = `${time?.toLocaleString(
    dateTimeFormatOptions,
  )} by ${authorName ?? '-'}`;
  return (
    <NoteContainer>
      <NoteBody>{note}</NoteBody>
      <NoteFooter>{noteFooterContent}</NoteFooter>
    </NoteContainer>
  );
};

export default NoteHolder;
