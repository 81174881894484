import React, { useState, useRef, useEffect, FormEvent } from 'react';

import styled from '@emotion/styled';
import { filter } from 'graphql-anywhere';

import * as Fuel from '@convoy/fuel';
import { useTestDrive } from '@convoy/lynx.testdrive';

import { TableBody, BodyCell } from '~/components/core/Table';
import {
  NetworkStatus,
  ServiceableTrailerInfoFragment,
} from '~/services/apollo';
import { flags } from '~/services/testdrive/featureFlags';

import TrailerRow from './TrailerRow';

const strings = {
  noTrailers: `No trailers available for servicing`,
};

const NoTrailers = styled(BodyCell)({
  justifyContent: 'center',
  alignItems: 'center',
});

interface Props {
  networkStatus: number;
  columnFlexSizes: Record<string, number>;
  trailers: ServiceableTrailerInfoFragment[];
  selectedServiceRequests: Set<string>;
  onSelectServiceRequest: (event: FormEvent<HTMLInputElement>) => void;
  onFetchMoreTrailers: () => void;
  totalFilteredCount?: number | null;
}

const TrailersTableBody = (props: Props) => {
  const {
    networkStatus,
    columnFlexSizes,
    trailers,
    selectedServiceRequests,
    onSelectServiceRequest,
    onFetchMoreTrailers,
    totalFilteredCount,
  } = props;

  const loading =
    networkStatus !== NetworkStatus.fetchMore &&
    networkStatus < NetworkStatus.ready;
  // isFetchRequested will default to false, manually set to true on a fetch request, then clear back to false after the networkStatus changes from fetchMore. This will prevent accidental re-queries while a fetch is in progress, but has not yet been requested by Apollo
  const [isFetchRequested, setIsFetchRequested] = useState(false);
  useEffect(() => {
    setIsFetchRequested(networkStatus === NetworkStatus.fetchMore);
  }, [networkStatus]);

  const [shouldUsePagination] = useTestDrive([flags.usePagination]);
  const isNextPage =
    networkStatus >= NetworkStatus.ready &&
    Boolean(totalFilteredCount) &&
    trailers.length < Number(totalFilteredCount);

  const paginateTriggerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const paginateTriggerObserver = new IntersectionObserver(
      ([paginateTrigger]) => {
        if (paginateTrigger.isIntersecting) {
          setIsFetchRequested(true);
          onFetchMoreTrailers();
        }
      },
    );
    if (paginateTriggerRef.current) {
      paginateTriggerObserver.observe(paginateTriggerRef.current);
    }
  }, [onFetchMoreTrailers]);

  if (loading) {
    return <Fuel.Spinner size='XXL' />;
  }

  if (trailers.length === 0) {
    return <NoTrailers>{strings.noTrailers}</NoTrailers>;
  }

  return (
    <TableBody>
      {trailers.map(trailer => (
        <TrailerRow
          key={trailer.key}
          columnFlexSizes={columnFlexSizes}
          trailer={filter(TrailerRow.ServiceableTrailerInfo, trailer)}
          selectedServiceRequests={selectedServiceRequests}
          onSelectServiceRequest={onSelectServiceRequest}
        />
      ))}
      {
        // Displays loading spinner when loading additional pages or pagination trigger if not loading, but additional pages are available
        shouldUsePagination &&
          (isFetchRequested ? (
            <Fuel.Spinner size='XXL' />
          ) : (
            <div
              ref={paginateTriggerRef}
              style={{ height: Fuel.Unit.XXS }}
              hidden={!isNextPage}
            />
          ))
      }
    </TableBody>
  );
};

export default TrailersTableBody;
