import React from 'react';

import { DateTime } from 'luxon';

import * as Fuel from '@convoy/fuel';
import { CheckboxProps } from '@convoy/fuel.form/Checkbox/Checkbox';

import { DateRangeFilter } from '~/services/apollo';

export type DueDateFilterType = DateRangeFilter & {
  filterValue?: boolean;
};

interface Props extends CheckboxProps {
  label: string;
  filterValue?: boolean;
  onFilter: (filters: { dueDate: DueDateFilterType }) => void;
  className?: string;
}

const OnlyOverdueFilter = (props: Props) => {
  const { label, filterValue, onFilter, className } = props;

  const onChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    const isChecked = event.target.checked;
    onFilter({
      dueDate: {
        filterValue: isChecked,
        before: isChecked
          ? DateTime.fromJSDate(new Date()).minus({ days: 1 }).endOf('day')
          : undefined,
      },
    });
  };

  return (
    <Fuel.Checkbox
      className={className}
      checked={filterValue ?? false}
      onChange={onChange}
    >
      {label}
    </Fuel.Checkbox>
  );
};

export default OnlyOverdueFilter;
