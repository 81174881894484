import * as GraphQL from '~/services/apollo';
import {
  CreateNewActivityFeedItemMutation,
  CreateNewActivityFeedItemMutationVariables,
  ExternallyPublishableEventTopics,
  MutationResult,
} from '~/services/apollo';

const useCreateTrailerServiceRequestReply = (): [
  (params: {
    note: string;
    trailerKey: string;
    trailerServiceRequestId: string;
    parentId: string;
    actorId: string;
  }) => Promise<void>,
  MutationResult<CreateNewActivityFeedItemMutation>,
] => {
  const [createFunction, statusFields] =
    GraphQL.useCreateNewActivityFeedItemMutation();
  const returnFunction = async (params: {
    note: string;
    trailerKey: string;
    trailerServiceRequestId: string;
    parentId: string;
    actorId: string;
  }): Promise<void> => {
    const { note, trailerKey, trailerServiceRequestId, parentId, actorId } =
      params;
    const input: CreateNewActivityFeedItemMutationVariables = {
      eventType: ExternallyPublishableEventTopics.TrailerManualNote,
      actorId,
      data: { note },
      entityKeys: {
        trailerId: trailerKey,
        trailerServiceRequestId,
        parentId,
      },
    };

    await createFunction({
      variables: {
        ...input,
      },
    });
  };
  return [returnFunction, statusFields];
};

export default useCreateTrailerServiceRequestReply;
