import React from 'react';

import * as Fuel from '@convoy/fuel';
import { CheckboxProps } from '@convoy/fuel.form/Checkbox/Checkbox';

import { DateRangeFilter } from '~/services/apollo';

export type DueDateFilterType = DateRangeFilter & {
  filterValue?: boolean;
};

interface Props extends CheckboxProps {
  label: string;
  filterValue?: boolean | null;
  onFilter: (filters: { hasNotes: boolean | null }) => void;
  className?: string;
}

const HasNotesFilter = (props: Props) => {
  const { label, filterValue, onFilter, className } = props;

  const onChange: React.ChangeEventHandler<HTMLInputElement> = event =>
    onFilter({ hasNotes: event.target.checked || null });

  return (
    <Fuel.Checkbox
      className={className}
      checked={filterValue ?? false}
      onChange={onChange}
    >
      {label}
    </Fuel.Checkbox>
  );
};

export default HasNotesFilter;
