import React from 'react';

import gql from 'graphql-tag';
import { DateTime } from 'luxon';

import * as Fuel from '@convoy/fuel';
import { SelectProps } from '@convoy/fuel.form/Select/Select';

import {
  LocationEventType,
  ServiceRequestType,
  useGetFilterableServiceableTrailersQuery,
} from '~/services/apollo';
import { compact } from '~/utils/nullish';

interface LocationOption {
  label: string;
  value: string;
}

interface Props extends SelectProps {
  filterValue?: string;
  onFilter: (filters: { parcelIds: string[] | null }) => void;
}

const LocationCodeFilter = (props: Props) => {
  const { trailers, filterValue, onFilter, ...selectProps } = props;

  const { data } = useGetFilterableServiceableTrailersQuery({
    variables: {
      searchOptions: {
        filters: {
          types: [ServiceRequestType.FhwaInspection],
          dueDate: {
            before: DateTime.fromJSDate(new Date())
              .plus({ days: 90 })
              .endOf('day')
              .toISO(),
          },
        },
      },
    },
    fetchPolicy: 'no-cache',
  });
  const filterableTrailers = compact(
    data?.trailersWithActiveServiceRequests.edges,
  );

  const locations = new Set();
  const locationOptions: LocationOption[] = [];
  let selectedLocation: LocationOption | null = null;

  filterableTrailers.forEach(trailer => {
    const { lastLocationEvent } = trailer;

    if (lastLocationEvent?.type === LocationEventType.Enter) {
      const parcelId = lastLocationEvent?.parcel?.id;
      const locationCode = lastLocationEvent?.parcel?.trailerYard?.locationCode;

      if (parcelId && locationCode && !locations.has(locationCode)) {
        const option = { label: locationCode, value: parcelId };
        locationOptions.push(option);
        if (filterValue === parcelId) {
          selectedLocation = option;
        }

        locations.add(locationCode);
      }
    }
  });

  locationOptions.sort((a, b) => (a.label > b.label ? 1 : -1));

  const onChange = (event?: { value: string }) => {
    const { value } = event || {};
    onFilter({ parcelIds: value ? [value] : null });
  };

  return (
    <Fuel.Select
      {...selectProps}
      isClearable
      isSearchable
      value={selectedLocation}
      options={locationOptions}
      onChange={onChange}
    />
  );
};

LocationCodeFilter.query = gql`
  query getFilterableServiceableTrailers(
    $searchOptions: ServiceRequestSearchOptions
  ) {
    trailersWithActiveServiceRequests(input: $searchOptions) {
      edges {
        id
        key
        lastLocationEvent {
          type
          parcel {
            id
            trailerYard {
              key
              locationCode
            }
          }
        }
      }
    }
  }
`;

export default LocationCodeFilter;
